<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Heart Rate Zones Calculator for Cycling</h1>
      <p class="text-muted mb-4 text-sm">
        Calculate your heart rate training zones. Choose from multiple calculation methods based on Maximum Heart Rate or Lactate Threshold Heart Rate (LTHR) to
        get your 5-Zone or 7-Zone training model.
      </p>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Method
          <div class="text-muted text-sm">Choose a method to calculate your training zones</div>
          <InputErrorMessage v-if="errors.power" :error="errors.power" />
        </div>
        <div class="FormGroup__content md:w-5/12">
          <SelectInput
            v-model="form.method"
            value-key="value"
            label-key="label"
            class="w-full"
            :options="calculationMethods"
            aria-label="Select calculation method"
          />
        </div>
      </div>

      <div v-if="selectedMethod.attribute === 'maxHr'" class="FormGroup FormGroup--last">
        <div class="FormGroup__label">
          Maximum Heart Rate
          <InputErrorMessage v-if="errors.maxHr" :error="errors.maxHr" />
        </div>
        <div class="FormGroup__content md:w-5/12">
          <TextInput v-model="form.maxHr" class="w-full mr-3" placeholder="e.g. 190" type="number" aria-label="Enter your Max HR" />
          <AppButton primary small @click="calculate()">Calculate</AppButton>
        </div>
      </div>

      <div v-if="selectedMethod.attribute === 'ltHr'" class="FormGroup FormGroup--last">
        <div class="FormGroup__label">
          Lactate Threshold Heart Rate (LTHR)
          <InputErrorMessage v-if="errors.ltHr" :error="errors.ltHr" />
        </div>
        <div class="FormGroup__content md:w-5/12">
          <TextInput v-model="form.ltHr" class="w-full mr-3" placeholder="e.g. 175" type="number" aria-label="Enter your LTHR" />
          <AppButton primary small @click="calculate()">Calculate</AppButton>
        </div>
      </div>
    </div>

    <div>
      <div class="p-6 mt-6 bg-white border rounded">
        <div class="label">Heart Rate Zones</div>
        <div>
          <table class="w-full">
            <thead>
              <tr class="text-xl font-bold">
                <th class="w-1/2 pb-4" align="left">Zone</th>
                <th class="w-1/4 pb-4" align="right">Heart Rate (bpm)</th>
              </tr>
            </thead>
            <tbody class="md:text-lg text-base">
              <tr v-for="(zone, index) in selectedMethod.zones">
                <td class="py-4 border-t" align="left">
                  <div class="font-bold">
                    <span :class="getColorClassname(index)" class="inline-block w-5 h-5 mr-1 text-sm font-medium rounded">&nbsp;</span>
                    {{ zone.name }}
                  </div>
                </td>
                <td class="py-4 border-t" align="right">
                  <div class="inline-flex">
                    <div v-if="result && form[selectedMethod.attribute]" class="w-18">
                      <span v-if="index == selectedMethod.zones.length - 1" class="mr-1">></span>
                      {{ (zone.from * form[selectedMethod.attribute]) | format() }}
                    </div>
                    <div v-if="index < selectedMethod.zones.length - 1" class="w-8 text-center">–</div>
                    <div v-if="result && form[selectedMethod.attribute] && index < selectedMethod.zones.length - 1" class="w-18">
                      {{ (zone.to * form[selectedMethod.attribute]) | format() }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="selectedMethod.zones[0].description" class="p-6 mt-6 bg-white border rounded">
        <div class="label">Training Zones Explained</div>
        <div>
          <div v-for="(zone, index) in selectedMethod.zones" class="mb-8">
            <h3>
              <span :class="getColorClassname(index)" class="inline-block w-5 h-5 mr-1 text-sm font-medium rounded">&nbsp;</span>
              {{ zone.name }}
            </h3>
            <p>{{ zone.description }}</p>
          </div>
        </div>
        <div v-if="selectedMethod.url">
          <a class="link" :href="selectedMethod.url" target="_blank">Read more</a>
        </div>
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Training with a power meter?</h2>
        Start tracking your cycling power and get actionable insights from your training. Our AI-powered tools are designed to help you become a stronger and
        faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

const calculationMethods = [
  {
    label: 'Maximum Heart Rate (5 Zones)',
    value: '5max',
    attribute: 'maxHr',
    description: '',
    zones: [
      {
        from: 0.5,
        to: 0.6,
        name: 'Active Recovery',
        description:
          '“Easy spinning” or “light pedal pressure”, i.e., very low level exercise, too low in and of itself to induce significant physiological adaptations. Minimal sensation of leg effort/fatigue. Requires no concentration to maintain pace, and continuous conversation possible. Typically used for active recovery after strenuous training days (or races), between interval efforts, or for socializing.'
      },
      {
        from: 0.6,
        to: 0.7,
        name: 'Aerobic Endurance',
        description:
          '“All day” pace, or classic long slow distance (LSD) training. Sensation of leg effort/fatigue generally low, but may rise periodically to higher levels (e.g., when climbing). Concentration generally required to maintain effort only at highest end of range and/or during longer training sessions. Breathing is more regular than at level 1, but continuous conversation still possible. Frequent (daily) training sessions of moderate duration (e.g., 2 h) at level 2 possible (provided dietary carbohydrate intake is adequate), but complete recovery from very long workouts may take more than 24 hs.'
      },
      {
        from: 0.7,
        to: 0.8,
        name: 'Tempo',
        description:
          'Typical intensity of fartlek workout, ‘spirited’ group ride, or briskly moving paceline. More frequent/greater sensation of leg effort/fatigue than at level 2. Requires concentration to maintain alone, especially at upper end of range, to prevent effort from falling back to level 2. Breathing deeper and more rhythmic than level 2, such that any conversation must be somewhat halting, but not as difficult as at level 4. Recovery from level 3 training sessions more difficult than after level 2 workouts, but consecutive days of level 3 training still possible if duration is not excessive and dietary carbohydrate intake is adequate.'
      },
      {
        from: 0.8,
        to: 0.9,
        name: 'Lactate Threshold',
        description:
          'Just below to just above TT effort, taking into account duration, current fitness, environmental conditions, etc. Essentially continuous sensation of moderate or even greater leg effort/fatigue. Continuous conversation difficult at best, due to depth/frequency of breathing. Effort sufficiently high that sustained exercise at this level is mentally very taxing – therefore typically performed in training as multiple ‘repeats’, ‘modules’, or ‘blocks’ of 10-30 min duration. Consecutive days of training at level 4 possible, but such workouts generally only performed when sufficiently rested/recovered from prior training so as to be able to maintain intensity.'
      },
      {
        from: 0.9,
        to: 1,
        name: 'VO2 Max',
        description:
          'Typical intensity of longer (3-8 min) intervals intended to increase VO2max. Strong to severe sensations of leg effort/fatigue, such that completion of more than 30-40 min total training time is difficult at best. Conversation not possible due to often ‘ragged’ breathing. Should generally be attempted only when adequately recovered from prior training – consecutive days of level 5 work not necessarily desirable even if possible.  Note: At this level, the average heart rate may not be due to slowness of heart rate response and/or ceiling imposed by maximum heart rate)'
      }
    ]
  },
  {
    label: 'Lactate Threshold (5 zones)',
    value: '5lthr',
    attribute: 'ltHr',
    description: '',
    zones: [
      {
        from: 0,
        to: 0.69,
        name: 'Active Recovery',
        description:
          '“Easy spinning” or “light pedal pressure”, i.e., very low level exercise, too low in and of itself to induce significant physiological adaptations. Minimal sensation of leg effort/fatigue. Requires no concentration to maintain pace, and continuous conversation possible. Typically used for active recovery after strenuous training days (or races), between interval efforts, or for socializing.'
      },
      {
        from: 0.69,
        to: 0.84,
        name: 'Aerobic Endurance',
        description:
          '“All day” pace, or classic long slow distance (LSD) training. Sensation of leg effort/fatigue generally low, but may rise periodically to higher levels (e.g., when climbing). Concentration generally required to maintain effort only at highest end of range and/or during longer training sessions. Breathing is more regular than at level 1, but continuous conversation still possible. Frequent (daily) training sessions of moderate duration (e.g., 2 h) at level 2 possible (provided dietary carbohydrate intake is adequate), but complete recovery from very long workouts may take more than 24 hs.'
      },
      {
        from: 0.84,
        to: 0.95,
        name: 'Tempo',
        description:
          'Typical intensity of fartlek workout, ‘spirited’ group ride, or briskly moving paceline. More frequent/greater sensation of leg effort/fatigue than at level 2. Requires concentration to maintain alone, especially at upper end of range, to prevent effort from falling back to level 2. Breathing deeper and more rhythmic than level 2, such that any conversation must be somewhat halting, but not as difficult as at level 4. Recovery from level 3 training sessions more difficult than after level 2 workouts, but consecutive days of level 3 training still possible if duration is not excessive and dietary carbohydrate intake is adequate.'
      },
      {
        from: 0.95,
        to: 1.06,
        name: 'Lactate Threshold',
        description:
          'Just below to just above TT effort, taking into account duration, current fitness, environmental conditions, etc. Essentially continuous sensation of moderate or even greater leg effort/fatigue. Continuous conversation difficult at best, due to depth/frequency of breathing. Effort sufficiently high that sustained exercise at this level is mentally very taxing – therefore typically performed in training as multiple ‘repeats’, ‘modules’, or ‘blocks’ of 10-30 min duration. Consecutive days of training at level 4 possible, but such workouts generally only performed when sufficiently rested/recovered from prior training so as to be able to maintain intensity.'
      },
      {
        from: 1.06,
        to: 1.5,
        name: 'VO2 Max',
        description:
          'Typical intensity of longer (3-8 min) intervals intended to increase VO2max. Strong to severe sensations of leg effort/fatigue, such that completion of more than 30-40 min total training time is difficult at best. Conversation not possible due to often ‘ragged’ breathing. Should generally be attempted only when adequately recovered from prior training – consecutive days of level 5 work not necessarily desirable even if possible.  Note: At this level, the average heart rate may not be due to slowness of heart rate response and/or ceiling imposed by maximum heart rate)'
      }
    ]
  },
  {
    label: 'Lactate Threshold (7 zones)',
    value: '7lthr',
    attribute: 'ltHr',
    description: '',
    zones: [
      {
        from: 0,
        to: 0.8,
        name: 'Zone 1: Recovery',
        description:
          'Also called "easy spinning," this form of exercise requires virtually no leg effort. It is typically used for casual cycling and recovery after races.'
      },
      {
        from: 0.8,
        to: 0.9,
        name: 'Zone 2: Aerobic',
        description:
          "It's a pace you can keep for the whole day, during long-distance trips. Fatigue level is low, but recovery from a long training session might take more than one day."
      },
      {
        from: 0.9,
        to: 0.94,
        name: 'Zone 3: Tempo',
        description: 'Typically used in interval training, it requires concentration to prevent from falling back to zone 2.'
      },
      {
        from: 0.94,
        to: 1.0,
        name: 'Zone 4: SubThreshold',
        description:
          'A continuous sensation of fatigue and leg effort, requires high concentration. Exercising in this zone typically leads to a high frequency of breathing. Should be practiced in 10-30 minute intervals.'
      },
      {
        from: 1.0,
        to: 1.03,
        name: 'Zone 5A: SuperThreshold',
        description:
          "Exercising in the zone slightly above the threshold also requires constant effort. It shouldn't be done for extended periods of time (over 30 minutes)."
      },
      {
        from: 1.03,
        to: 1.07,
        name: 'Zone 5B: Aerobic Capacity',
        description:
          'This is the typical intensity of intervals lasting for three to eight minutes. Training in this zone helps increase VO₂max (the maximum oxygen consumption).'
      },
      {
        from: 1.07,
        to: 1.5,
        name: 'Zone 5C: Anaerobic Capacity',
        description:
          "Training should only occur in short (30 seconds to 3 minutes), high-intensity intervals. You will experience a severe sensation of leg effort. When exercising in this zone, you shouldn't use the heart rate as your intensity indicator."
      }
    ]
  }
]

export default {
  data() {
    return {
      form: {
        method: '5max',
        maxHr: undefined,
        ltHr: undefined
      },
      errors: {},
      calculationMethods,
      result: undefined
    }
  },

  computed: {
    selectedMethod() {
      return this.calculationMethods.find(({ value }) => value === this.form.method)
    }
  },

  methods: {
    getColorClassname(index) {
      switch (index) {
        case 0:
          return 'bg-green-200'
        case 1:
          return 'bg-green-400'
        case 2:
          return 'bg-yellow-500'
        case 3:
          return 'bg-orange-400'
        case 4:
          return 'bg-red-400'
        case 5:
          return 'bg-red-500'
        case 6:
          return 'bg-red-600'
        case 7:
          return 'bg-red-800'
      }
    },

    async calculate() {
      this.errors = {}

      const rules =
        this.selectedMethod.attribute == 'maxHr'
          ? {
            maxHr: [isRequired(), isMin(100), isMax(260)]
          }
          : {
            ltHr: [isRequired(), isMin(100), isMax(260)]
          }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        this.result = this.selectedMethod.attribute == 'maxHr' ? this.form.maxHr : this.form.ltHr
      }
    }
  },

  metaInfo() {
    return {
      title: `Cycling Heart Rate Zones Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Online Heart Rate Training Zones Calculator for cycling. Choose from multiple calculation methods based on Maximum Heart Rate or Lactate Threshold Heart Rate (LTHR) to get your 5-Zone or 7-Zone training model.'
        }
      ]
    }
  }
}
</script>
