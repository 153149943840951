<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Cycling Power Zones Calculator</h1>
      <p class="text-muted mb-4 text-sm">
        Use this tool to calculate your cycling power training zones. Choose from multiple power zone calculation methods based on Functional Threshold Power
        (FTP) – Dr. Andrew Coggan, British Cycling, Carmichael Training Systems (CTS), USA Triathlon (USAT), Durata, 80/20 Endurance or MyProCoach.
      </p>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Your Functional Threshold Power (FTP)
          <div class="text-muted text-sm">
            Not sure what this means? Use our
            <router-link :to="{ name: 'tools.ftp' }" title="Cycling FTP Power Calculator">FTP calculator.</router-link>
          </div>
          <InputErrorMessage v-if="errors.ftp" :error="errors.ftp" />
        </div>
        <div class="FormGroup__content md:w-5/12">
          <TextInput v-model="form.ftp" class="w-full mr-3" placeholder="e.g. 300" type="number" aria-label="Enter your ftp" />
          <AppButton primary small @click="calculate()">Calculate</AppButton>
        </div>
      </div>

      <div class="FormGroup FormGroup--last">
        <div class="FormGroup__label">
          Method
          <div class="text-muted text-sm">Choose a method to calculate your power training zones</div>
          <InputErrorMessage v-if="errors.power" :error="errors.power" />
        </div>
        <div class="FormGroup__content md:w-5/12">
          <SelectInput
            v-model="form.method"
            value-key="value"
            label-key="label"
            class="w-full"
            :options="calculationMethods"
            aria-label="Select calculation method"
          />
        </div>
      </div>
    </div>

    <div>
      <div class="p-6 mt-6 bg-white border rounded">
        <div class="label">Power Training Zones</div>
        <div>
          <table class="w-full">
            <thead>
              <tr class="text-xl font-bold">
                <th class="w-1/2 pb-4" align="left">Power Zone</th>
                <th class="w-1/4 pb-4" align="right">Power (Watts)</th>
              </tr>
            </thead>
            <tbody class="md:text-lg text-base">
              <tr v-for="(zone, index) in selectedMethod.zones">
                <td class="py-4 border-t" align="left">
                  <div class="font-bold">
                    <span :class="getColorClassname(index)" class="inline-block w-5 h-5 mr-1 text-sm font-medium rounded">&nbsp;</span>
                    {{ zone.name }}
                  </div>
                </td>
                <td class="py-4 border-t" align="right">
                  <div class="inline-flex">
                    <div v-if="result" class="w-14">
                      {{ (zone.from * result) | format('power') }}
                    </div>
                    <div class="w-8 text-center">–</div>
                    <div v-if="result" class="w-14">
                      {{ (zone.to * result) | format('power') }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="selectedMethod.zones[0].description" class="p-6 mt-6 bg-white border rounded">
        <div class="label">Training Zones Explained</div>
        <div>
          <div v-for="(zone, index) in selectedMethod.zones" class="mb-8">
            <h3>
              <span :class="getColorClassname(index)" class="inline-block w-5 h-5 mr-1 text-sm font-medium rounded">&nbsp;</span>
              {{ zone.name }}
            </h3>
            <p>{{ zone.description }}</p>
          </div>
        </div>
        <div v-if="selectedMethod.url">
          <a class="link" :href="selectedMethod.url" target="_blank">Read more</a>
        </div>
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Training with a power meter?</h2>
        Start tracking your cycling power and get actionable insights from your training. Our AI-powered tools are designed to help you become a stronger and
        faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

const calculationMethods = [
  {
    label: 'Dr. Andrew Coggan',
    value: 'coggan',
    url: 'https://www.trainingpeaks.com/blog/power-training-levels/',
    description: '',
    zones: [
      {
        from: 0,
        to: 0.557,
        name: 'Active Recovery',
        description:
          '“Easy spinning” or “light pedal pressure”, i.e., very low level exercise, too low in and of itself to induce significant physiological adaptations. Minimal sensation of leg effort/fatigue. Requires no concentration to maintain pace, and continuous conversation possible. Typically used for active recovery after strenuous training days (or races), between interval efforts, or for socializing.'
      },
      {
        from: 0.558,
        to: 0.757,
        name: 'Aerobic Endurance',
        description:
          '“All day” pace, or classic long slow distance (LSD) training. Sensation of leg effort/fatigue generally low, but may rise periodically to higher levels (e.g., when climbing). Concentration generally required to maintain effort only at highest end of range and/or during longer training sessions. Breathing is more regular than at level 1, but continuous conversation still possible. Frequent (daily) training sessions of moderate duration (e.g., 2 h) at level 2 possible (provided dietary carbohydrate intake is adequate), but complete recovery from very long workouts may take more than 24 hs.'
      },
      {
        from: 0.758,
        to: 0.907,
        name: 'Tempo',
        description:
          'Typical intensity of fartlek workout, ‘spirited’ group ride, or briskly moving paceline. More frequent/greater sensation of leg effort/fatigue than at level 2. Requires concentration to maintain alone, especially at upper end of range, to prevent effort from falling back to level 2. Breathing deeper and more rhythmic than level 2, such that any conversation must be somewhat halting, but not as difficult as at level 4. Recovery from level 3 training sessions more difficult than after level 2 workouts, but consecutive days of level 3 training still possible if duration is not excessive and dietary carbohydrate intake is adequate.'
      },
      {
        from: 0.908,
        to: 1.057,
        name: 'Lactate Threshold',
        description:
          'Just below to just above TT effort, taking into account duration, current fitness, environmental conditions, etc. Essentially continuous sensation of moderate or even greater leg effort/fatigue. Continuous conversation difficult at best, due to depth/frequency of breathing. Effort sufficiently high that sustained exercise at this level is mentally very taxing – therefore typically performed in training as multiple ‘repeats’, ‘modules’, or ‘blocks’ of 10-30 min duration. Consecutive days of training at level 4 possible, but such workouts generally only performed when sufficiently rested/recovered from prior training so as to be able to maintain intensity.'
      },
      {
        from: 1.058,
        to: 1.207,
        name: 'VO2 Max',
        description:
          'Typical intensity of longer (3-8 min) intervals intended to increase VO2max. Strong to severe sensations of leg effort/fatigue, such that completion of more than 30-40 min total training time is difficult at best. Conversation not possible due to often ‘ragged’ breathing. Should generally be attempted only when adequately recovered from prior training – consecutive days of level 5 work not necessarily desirable even if possible.  Note: At this level, the average heart rate may not be due to slowness of heart rate response and/or ceiling imposed by maximum heart rate)'
      },
      {
        from: 1.208,
        to: 2.0,
        name: 'Anaerobic Capacity',
        description:
          'Short (30 s to 3 min), high intensity intervals designed to increase anaerobic capacity. Heart rate generally not useful as guide to intensity due to non-steady-state nature of effort. Severe sensation of leg effort/fatigue, and conversation impossible. Consecutive days of extended level 6 training usually not attempted.'
      },
      {
        from: 0,
        to: 0,
        name: 'Neuromuscular power',
        description:
          'Very short, very high intensity efforts (e.g., jumps, standing starts, short sprints) that generally place greater stress on musculoskeletal rather than metabolic systems. Power useful as guide, but only in reference to prior similar efforts, not TT pace.'
      }
    ]
  },
  {
    label: 'British Cycling',
    value: 'british',
    url: 'https://www.britishcycling.org.uk/membership/article/20120925-Power-Calculator-0',
    description: '',
    zones: [
      {
        from: 0,
        to: 0.522,
        name: 'Active Recovery',
        description:
          '“Easy spinning” or “light pedal pressure”, i.e., very low level exercise, too low in and of itself to induce significant physiological adaptations. Minimal sensation of leg effort/fatigue. Requires no concentration to maintain pace, and continuous conversation possible. Typically used for active recovery after strenuous training days (or races), between interval efforts, or for socializing.'
      },
      {
        from: 0.522,
        to: 0.722,
        name: 'Endurance',
        description:
          '“All day” pace, or classic long slow distance (LSD) training. Sensation of leg effort/fatigue generally low, but may rise periodically to higher levels (e.g., when climbing). Concentration generally required to maintain effort only at highest end of range and/or during longer training sessions. Breathing is more regular than at level 1, but continuous conversation still possible. Frequent (daily) training sessions of moderate duration (e.g., 2 h) at level 2 possible (provided dietary carbohydrate intake is adequate), but complete recovery from very long workouts may take more than 24 hs.'
      },
      {
        from: 0.722,
        to: 0.855,
        name: 'Tempo',
        description:
          'Typical intensity of fartlek workout, ‘spirited’ group ride, or briskly moving paceline. More frequent/greater sensation of leg effort/fatigue than at level 2. Requires concentration to maintain alone, especially at upper end of range, to prevent effort from falling back to level 2. Breathing deeper and more rhythmic than level 2, such that any conversation must be somewhat halting, but not as difficult as at level 4. Recovery from level 3 training sessions more difficult than after level 2 workouts, but consecutive days of level 3 training still possible if duration is not excessive and dietary carbohydrate intake is adequate.'
      },
      {
        from: 0.855,
        to: 0.997,
        name: 'Threshold',
        description:
          'Just below to just above TT effort, taking into account duration, current fitness, environmental conditions, etc. Essentially continuous sensation of moderate or even greater leg effort/fatigue. Continuous conversation difficult at best, due to depth/frequency of breathing. Effort sufficiently high that sustained exercise at this level is mentally very taxing – therefore typically performed in training as multiple ‘repeats’, ‘modules’, or ‘blocks’ of 10-30 min duration. Consecutive days of training at level 4 possible, but such workouts generally only performed when sufficiently rested/recovered from prior training so as to be able to maintain intensity.'
      },
      {
        from: 0.997,
        to: 1.14,
        name: 'VO2 max',
        description:
          'Typical intensity of longer (3-8 min) intervals intended to increase VO2max. Strong to severe sensations of leg effort/fatigue, such that completion of more than 30-40 min total training time is difficult at best. Conversation not possible due to often ‘ragged’ breathing. Should generally be attempted only when adequately recovered from prior training – consecutive days of level 5 work not necessarily desirable even if possible.  Note: At this level, the average heart rate may not be due to slowness of heart rate response and/or ceiling imposed by maximum heart rate)'
      },
      {
        from: 1.14,
        to: 1.425,
        name: 'Anaerobic capacity',
        description:
          'Short (30 s to 3 min), high intensity intervals designed to increase anaerobic capacity. Heart rate generally not useful as guide to intensity due to non-steady-state nature of effort. Severe sensation of leg effort/fatigue, and conversation impossible. Consecutive days of extended level 6 training usually not attempted.'
      }
    ]
  },
  {
    label: 'Durata Training',
    value: 'durata',
    url: 'http://duratatraining.com/',
    description: 'Zones originally used by Durata Training.',
    zones: [
      {
        from: 0.55,
        to: 0.74,
        name: 'Endurance Ride',
        description: ''
      },
      {
        from: 0.4,
        to: 0.5,
        name: 'Active Recovery',
        description: ''
      },
      {
        from: 0.75,
        to: 0.88,
        name: 'Tempo',
        description: ''
      },
      {
        from: 0.9,
        to: 0.95,
        name: 'Steady State',
        description: ''
      },
      {
        from: 0.95,
        to: 1.05,
        name: 'Threshold Intervals',
        description: ''
      },
      {
        from: 1.1,
        to: 1.5,
        name: 'Maximal Efforts',
        description: ''
      },
      {
        from: 1.5,
        to: 1.7,
        name: 'Micro',
        description: ''
      },
      {
        from: 1.15,
        to: 1.3,
        name: 'Supra Threshold',
        description: ''
      }
    ]
  },
  {
    label: 'Carmichael Training Systems (CTS)',
    value: 'cts',
    description: 'Zones used by coaches at Carmichael Training Systems, and in The Time Crunched Cyclist and Time Crunched Triathlete by Chris Carmichael.',
    url: 'https://trainright.com/cycling-field-test/',
    zones: [
      {
        from: 0,
        to: 0.557,
        name: 'Recovery',
        description:
          '“Easy spinning” or “light pedal pressure”, i.e., very low level exercise, too low in and of itself to induce significant physiological adaptations. Minimal sensation of leg effort/fatigue. Requires no concentration to maintain pace, and continuous conversation possible. Typically used for active recovery after strenuous training days (or races), between interval efforts, or for socializing.'
      },
      {
        from: 0.557,
        to: 0.757,
        name: 'Endurance',
        description:
          '“All day” pace, or classic long slow distance (LSD) training. Sensation of leg effort/fatigue generally low, but may rise periodically to higher levels (e.g., when climbing). Concentration generally required to maintain effort only at highest end of range and/or during longer training sessions. Breathing is more regular than at level 1, but continuous conversation still possible. Frequent (daily) training sessions of moderate duration (e.g., 2 h) at level 2 possible (provided dietary carbohydrate intake is adequate), but complete recovery from very long workouts may take more than 24 hs.'
      },
      {
        from: 0.757,
        to: 0.907,
        name: 'Tempo',
        description:
          'Typical intensity of fartlek workout, ‘spirited’ group ride, or briskly moving paceline. More frequent/greater sensation of leg effort/fatigue than at level 2. Requires concentration to maintain alone, especially at upper end of range, to prevent effort from falling back to level 2. Breathing deeper and more rhythmic than level 2, such that any conversation must be somewhat halting, but not as difficult as at level 4. Recovery from level 3 training sessions more difficult than after level 2 workouts, but consecutive days of level 3 training still possible if duration is not excessive and dietary carbohydrate intake is adequate.'
      },
      {
        from: 0.907,
        to: 1.057,
        name: 'Threshold',
        description:
          'Just below to just above TT effort, taking into account duration, current fitness, environmental conditions, etc. Essentially continuous sensation of moderate or even greater leg effort/fatigue. Continuous conversation difficult at best, due to depth/frequency of breathing. Effort sufficiently high that sustained exercise at this level is mentally very taxing – therefore typically performed in training as multiple ‘repeats’, ‘modules’, or ‘blocks’ of 10-30 min duration. Consecutive days of training at level 4 possible, but such workouts generally only performed when sufficiently rested/recovered from prior training so as to be able to maintain intensity.'
      },
      {
        from: 1.057,
        to: 1.207,
        name: 'VO2 Max',
        description:
          'Typical intensity of longer (3-8 min) intervals intended to increase VO2max. Strong to severe sensations of leg effort/fatigue, such that completion of more than 30-40 min total training time is difficult at best. Conversation not possible due to often ‘ragged’ breathing. Should generally be attempted only when adequately recovered from prior training – consecutive days of level 5 work not necessarily desirable even if possible.  Note: At this level, the average heart rate may not be due to slowness of heart rate response and/or ceiling imposed by maximum heart rate)'
      },
      {
        from: 1.207,
        to: 2.0,
        name: 'Anaerobic Capacity',
        description:
          'Short (30 s to 3 min), high intensity intervals designed to increase anaerobic capacity. Heart rate generally not useful as guide to intensity due to non-steady-state nature of effort. Severe sensation of leg effort/fatigue, and conversation impossible. Consecutive days of extended level 6 training usually not attempted.'
      }
    ]
  },
  {
    label: 'USA Triathlon (USAT)',
    value: 'usat',
    url: 'https://www.teamusa.org/usa-triathlon/usat-for-me',
    description: 'Power zones used by USA Triathlon coaches',
    zones: [
      {
        from: 0,
        to: 0.59,
        name: 'Recovery',
        description: ''
      },
      {
        from: 0.59,
        to: 0.654,
        name: 'Gray Zone',
        description: ''
      },
      {
        from: 0.654,
        to: 0.778,
        name: 'Zone 1',
        description: ''
      },
      {
        from: 0.778,
        to: 0.894,
        name: 'Zone 2',
        description: ''
      },
      {
        from: 0.894,
        to: 1.0,
        name: 'Zone 3',
        description: ''
      },
      {
        from: 1.0,
        to: 2.0,
        name: 'Best Sustainable Effort',
        description: ''
      }
    ]
  },
  {
    label: 'Matt Fitzgerald’s 80/20 Endurance',
    value: '8020',
    url: 'https://www.8020endurance.com/understanding-your-8020-run-plan',
    description: '',
    zones: [
      {
        from: 0.5,
        to: 0.7,
        name: 'Zone 1',
        description:
          'Zone 1 is a very low intensity. Staying within it usually requires that you actively hold yourself back to a pace that’s slower than your natural running pace. The common exception is when a Zone 1 recovery jog follows a tiring high-intensity effort. The important thing to understand is that it’s almost impossible to go too slow when you’re aiming for Zone 1, whereas it’s very easy and all too common to go too fast.'
      },
      {
        from: 0.7,
        to: 0.83,
        name: 'Zone 2',
        description:
          'Zone 2 is fairly broad. You might wonder, “Where exactly within this zone should I be?” As a general rule, we encourage runners to go by feel. If you feel strong, run near the top end of Zone 2. If you feel tired or sluggish, go ahead and allow yourself to run near the bottom end.'
      },
      {
        from: 0.83,
        to: 0.91,
        name: 'Zone X',
        description:
          'Zone X is given a letter name instead of a numerical name because it is generally avoided in training. It’s more of a gap between Zones 2 and 3 than a zone unto itself. Zone X represents the “moderate-intensity rut” that most runners get stuck in without realizing it before they adopt the 80/20 method and learn to slow down in their easy runs and long runs. However, Zone X does overlap with race intensity for many runners at the half-marathon and marathon distance. For this reason, many of our 80/20 Run plans include Steady State Runs, Half-Marathon Pace Runs, and/or Marathon Pace Runs that target Zone X. This zone is also targeted briefly in some Fartlek Runs and Progression Runs.'
      },
      {
        from: 0.91,
        to: 1.0,
        name: 'Zone 3',
        description:
          'Zone 3 corresponds to lactate threshold intensity. Thinking in “threshold” terms can help you find this zone and stay in it by feel. The feeling of running in Zone 3 is often described as “comfortably hard,” or as the fastest speed that still feels relaxed. When you perform a Zone 3 effort, imagine there’s a cliff edge in front of you that represents the feeling of strain that accompanies faster speeds. Always stay one or two steps back from this precipice when training in Zone 3.'
      },
      {
        from: 1.0,
        to: 1.02,
        name: 'Zone Y',
        description:
          'Like Zone X, Zone Y is more of a gap between zones than a one unto itself. It’s a little too fast for threshold workouts, which target Zone 3, and a little too slow for high-intensity interval workouts, which offer more fitness bang for your workout buck when done in Zones 4 and 5.'
      },
      {
        from: 1.02,
        to: 1.1,
        name: 'Zone 4',
        description:
          'Mastering this zone is a matter of connecting the pace, power, and/or heart rate numbers that define the zone with what it feels like to run at that pace, power, or heart rate, so that you are able to reliably start each Zone 4 effort at the right intensity. If you mess it up the first few times, either going too slow or too fast, don’t sweat it. In fact, getting it wrong today is the best way to get it right tomorrow.'
      },
      {
        from: 1.1,
        to: 2.0,
        name: 'Zone 5',
        description:
          'Zone 5 is almost always used in interval workouts, including Hill Repetitions Runs. This intensity zone ranges from the highest speed you can sustain for a few minutes all the way to a full sprint. So how fast should you actually run Zone 5 efforts? Tailor your pace to the specific format of the workout. The rule of thumb here is to run closer to the bottom end of Zone 5 when these efforts are longer and closer to the top end when the intervals are shorter. For example, if a workout asks you to run a bunch of 1-minute intervals in Zone 5, you’ll want to control your pace so that you are able to run all of the intervals at the same speed without slowing down. But if a workout prescribes a set of 20-second intervals, you’ll want to run them as relaxed sprints.'
      }
    ]
  },
  {
    label: 'MyProCoach',
    value: 'myprocoach',
    url: 'https://www.myprocoach.net/calculators/bike-zones/',
    description: '',
    zones: [
      {
        from: 0,
        to: 0.55,
        name: 'Zone 1 – Easy',
        description: ''
      },
      {
        from: 0.55,
        to: 0.75,
        name: 'Zone 2 – Steady',
        description: ''
      },
      {
        from: 0.75,
        to: 0.9,
        name: 'Zone 3 – Moderately Hard',
        description: ''
      },
      {
        from: 0.9,
        to: 1.05,
        name: 'Zone 4 – Hard',
        description: ''
      },
      {
        from: 1.05,
        to: 2.0,
        name: 'Zone 5 – Very Hard',
        description: ''
      }
    ]
  }
]

export default {
  data() {
    return {
      form: {
        method: 'coggan'
      },
      errors: {},
      calculationMethods,
      result: undefined
    }
  },

  computed: {
    selectedMethod() {
      return this.calculationMethods.find(({ value }) => value === this.form.method)
    }
  },

  methods: {
    getColorClassname(index) {
      switch (index) {
        case 0:
          return 'bg-purple-100'
          break
        case 1:
          return 'bg-purple-200'
          break
        case 2:
          return 'bg-purple-300'
          break
        case 3:
          return 'bg-purple-400'
          break
        case 4:
          return 'bg-purple-500'
          break
        case 5:
          return 'bg-purple-600'
          break
        case 6:
          return 'bg-purple-700'
          break
        case 7:
          return 'bg-purple-800'
          break
        default:
      }
    },

    async calculate() {
      this.errors = {}

      const rules = {
        ftp: [isRequired(), isMin(100), isMax(700)]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        this.result = this.form.ftp
      }
    }
  },

  metaInfo() {
    return {
      title: `Cycling Power Zones Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Online Power Training Zones Calculator for cycling. Choose from multiple power zone calculation methods based on Functional Threshold Power (FTP) – Dr. Andrew Coggan, British Cycling, Carmichael Training Systems (CTS), USA Triathlon (USAT), Durata, 80/20 Endurance or MyProCoach.'
        }
      ]
    }
  }
}
</script>
